import { Component, OnInit, Input, OnChanges, ChangeDetectorRef, ViewChild, AfterViewInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';

import hotspots from '../../../assets/json/hotspots.json';

import * as Hammer from 'hammerjs';
import { DeviceService } from 'src/app/services/device.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-hotspot-modal',
    templateUrl: './hotspot-modal.component.html',
    styleUrls: ['./hotspot-modal.component.scss']
})
export class HotspotModalComponent implements OnChanges, AfterViewInit {

    hotspots = hotspots;

    @ViewChild('anchor') anchor;
    @ViewChild('container') container;

    @Input() id;
    @Input() section;

    content;
    hotspotArr;


    constructor(private router: Router, public deviceService: DeviceService, private analyticsService: AnalyticsService) { }

    ngAfterViewInit(): void {
        // this.loadModel();
        if(this.deviceService.touchIsEnabled())
            this.initHammer();
    }

    @HostBinding('style.height.px') 
    get height() {
        return window.innerHeight;
    }


    ngOnChanges(changes) {
        if (changes.id) {
            this.content = hotspots.find((hotspot) => hotspot.group == this.id && hotspot.section == this.section);
            this.hotspotArr = hotspots.filter((hotspot) => hotspot.section == this.section);
            let videoEl = document.getElementById('video') as HTMLVideoElement;
            if (this.content.mediaPreview && videoEl) {
                videoEl.src = `./assets/videos/${this.content.mediaPreview}`
            }

        }
    }

    initHammer() {
        if (!this.deviceService.isMobileSize() && !this.deviceService.isTabletSize()) return;
        let ref = this.container.nativeElement;
        let hammertime = new Hammer(ref, { direction: Hammer.DIRECTION_HORIZONTAL });
        hammertime.domEvents = true;
        hammertime.on('panend', (e) => this.handleSwipe(e));
    }

    handleSwipe(e) {
        if (e.direction == 2) {
            this.nextHotspot();
        }
        else if (e.direction == 4) {
            this.previousHotspot();
        }

    }

    goToHotspot(id) {
        this.router.navigate([], { queryParams: { hotspot: id }, queryParamsHandling: 'merge' });
    }

    nextHotspot() {
        let currentIdx = this.hotspotArr.findIndex((hotspot) => hotspot.group == this.content.group);
        let group;

        if (currentIdx == this.hotspotArr.length - 1) {
            group = this.hotspotArr[0].group;
        }
        else {
            group = this.hotspotArr[currentIdx + 1].group;
        }
        this.analyticsService.sendEvent('hotspot', this.content.group, group);
        this.goToHotspot(group);
    }

    previousHotspot() {
        let currentIdx = this.hotspotArr.findIndex((hotspot) => hotspot.group == this.content.group);
        let group;

        if (currentIdx == 0) {
            group = this.hotspotArr[this.hotspotArr.length - 1].group;
        }
        else {
            group = this.hotspotArr[currentIdx - 1].group;
        }

        this.analyticsService.sendEvent('hotspot', this.section, group);
        this.goToHotspot(group);
    }

    close() {
        this.router.navigate([], { queryParams: { hotspot: null }, queryParamsHandling: 'merge' });
    }

    hidePrivateContent(){
        const showPrivateContent = environment.showPrivateContent;
        // console.log("HotspotModalComponent -> showPrivateContent -> showHiddenContent", showPrivateContent)
        return this.content.productIsPrivate === true && !showPrivateContent;
    }

}
