import { Component, ElementRef, AfterViewInit, HostListener, Output, EventEmitter, OnInit, OnChanges, HostBinding, Input, ViewChild } from '@angular/core';
import { GltfObjectComponent } from '../gltf-object/gltf-object.component';
import * as THREE from 'three';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceService } from 'src/app/services/device.service';
import TWEEN from '@tweenjs/tween.js/dist/tween.esm.js';
import SceneComposer, { EffectComposer } from './scene-composer';

import Stats from './threestats';
import content from '../../../assets/json/content.json';
import hotspots from '../../../assets/json/hotspots.json';
import { Vector3 } from 'three';
import BGVideo from './bg-video';
import ParticleEngine from './particles';
import { AnalyticsService } from 'src/app/services/analytics.service';
import EvolutionOverlay from './evolution-overlay';

@Component({
    selector: 'app-skateboard',
    templateUrl: './skateboard.component.html',
    styleUrls: ['./skateboard.component.scss']
})
export class SkateboardComponent extends GltfObjectComponent implements AfterViewInit {


    @Input() filePath: string;
    @ViewChild('anchor') anchor: ElementRef;
    @Output() loadComplete = new EventEmitter();
    scrollContainer: HTMLElement;

    content = content;
    hotspots = hotspots;

    currentHotspots = [];
    currentSectionIdx;
    currentSection;
    nextSection;
    videos: BGVideo[] = [];

    coords = { x: 0, y: 0, z: 0 };
    params;

    objectScale = new THREE.Vector3(4, 4, 4);
    objectPosition = new THREE.Vector3(0, -16, 0);

    mouseOffset = new Vector3();
    inInteractiveSection = false;
    userRotating = false;
    cameraTarget = new Vector3();
    stats: Stats;
    sceneComposer: SceneComposer;

    zoomedIn = false;
    shouldRender = true;

    selectedHotspot;
    showVideos = true;
    skateboardVisible = false;
    skateboard3dObj: THREE.Object3D;
    evolutionOverlay: EvolutionOverlay;


    particleEngine;
    enableParticles;
    showParticles;

    readonly ZOOM_DISTANCE = 2;

    constructor(public elRef: ElementRef, private route: ActivatedRoute, public deviceService: DeviceService, private router: Router, private analyticsService: AnalyticsService) {
        super();
        this.showVideos = !this.deviceService.isMobile();
        this.enableParticles = !this.deviceService.isMobile();
        // this.hotspots = this.hotspots.filter( h => h.type!=='image');

        route.queryParams.subscribe((params) => {
            this.params = params;
            let idx = this.content.findIndex((c) => c.id == params.section);
            this.currentSection = this.content[idx];
            this.nextSection = this.content[idx + 1];

            if (this.currentSection) {
                this.setCoords();
                this.setCurrentHotspots();
                this.enableBackground(params);
                this.toggleParticles();
                this.enableEvoSection();
                this.updateControls();
                if (this.params.hotspot === undefined && this.zoomedIn)
                    this.zoomOut();

            }
        })

    }

    

    async init() {
        super.init();
        this.scene.name = `SkateboardComponent::${this.filePath}`;
        this.controls.addEventListener('start', this.userRotationStart.bind(this));
        this.createComposer();
        this.loadEnvMap();
        this.anchor.nativeElement.appendChild(this.renderer.domElement);
        this.renderer.info.autoReset = false;

        if (this.deviceService.isMobile() || this.deviceService.isPortrait()) {
            this.controls.enabled = false;
        }
    }

    createComposer() {
        this.sceneComposer = new SceneComposer(this.renderer, this.scene, this.camera);
    }

    createStats() {
        this.stats = new Stats();
    }

    enableScrollFns() {
        this.scrollContainer = document.getElementById('scroll-container');
        this.scrollContainer.addEventListener('scroll', () => this.setCoords())
        this.width = this.elRef.nativeElement.clientWidth;
        this.height = this.deviceService.isLandscape() ? window.innerHeight : this.elRef.nativeElement.clientHeight;

    }

    async ngAfterViewInit() {
        this.enableScrollFns();

        this.init();
        this.offsetCameraOnDesktop();//TODO <-- fix me
        this.createStats();
        this.animate();

        if (this.showVideos) {
            await this.loadBackgroundVideos();
            this.enableBackground();

        }
        await this.loadEvolutionImages();

        super.loadModel(this.filePath, this.onModelLoad.bind(this));
        this.setCoords();
    }

    onModelLoad() {
        this.updateControls();
        this.appendHotspots();
        this.enableEvoSection();
        this.addParticleSystem();
        this.toggleParticles();
        this.loadComplete.emit();
        window.addEventListener('mousemove', this.mouseMove.bind(this));
        // window.addEventListener('click', this.click.bind(this));
    }

    addParticleSystem() {
        if (!this.enableParticles) return;
        this.particleEngine = new ParticleEngine();
        this.particleEngine.init(this.scene);
    }


    clock = new THREE.Clock();

    animate() {
        requestAnimationFrame(this.animate.bind(this));
        if (!this.shouldRender)
            return;
        this.renderer.info.reset();

        this.controls.update();
        TWEEN.update();
        this.sceneComposer.render();

        this.updateModelPosition();
        this.updateHotspotPos();

        this.stats.update(this.sceneComposer.composer);


        var dt = this.clock.getDelta();
	    this.particleEngine?.update( dt * 0.5 );	
    }

    // ===================== Camera ===================== //
    mouseMove(e) {
        if (this.deviceService.isMobile()) return;
        if (this.zoomedIn) return;
        if (!this.currentSection || this.currentSection.id == 'landing' || this.params.hotspot) { return };
        this.mouseOffset.x = (this.width / 2 - e.clientX) * 0.001;
        this.mouseOffset.y = (this.height / 2 - e.clientY) * 0.001;


    }


    @HostListener('window:resize')
    @HostListener('window:orientationchange')
    onWindowResize() {
        
        // const canvas = this.renderer.domElement;
        // const width = canvas.clientWidth;
        // const height = canvas.clientHeight;

        this.width = this.elRef.nativeElement.clientWidth;

        this.height = this.deviceService.isLandscape() ? window.innerHeight : this.elRef.nativeElement.clientHeight;
        this.enableScrollFns();
        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.width, this.height);
        this.offsetCameraOnDesktop();
    }

    offsetCameraOnDesktop() {
        if (this.deviceService.isLandscape()) {
            if (window.innerWidth >= 1200) {
                this.camera.setViewOffset(this.width * 1.28, this.height * 1.2, 0, 0, this.width, this.height);
            }
            else if (window.innerWidth >= 992) {
                this.camera.setViewOffset(this.width * 1.38, this.height * 1.2, 0, 0, this.width, this.height);
            }
            else {
                this.camera.setViewOffset(this.width * 1.5, this.height * 1.3, 0, 0, this.width, this.height);
            }
        }

        if (this.deviceService.isPortrait()) {
            if (this.deviceService.isTabletSize() || this.deviceService.isDesktopSize()) {
                this.camera.setViewOffset(this.width, this.height, 0, 0, this.width, this.height);
            }
            else {
                this.camera.setViewOffset(this.width, this.height * 1.1, 0, 0, this.width, this.height);
            }
        }

    }

    userRotationStart() {
        this.userRotating = true;
    }

    updateModelPosition() {
        if (!this.inInteractiveSection && this.scrollContainer.scrollTop === 0) {
            this.inInteractiveSection = true;
        }

        if (this.inInteractiveSection && this.scrollContainer.scrollTop > 0) {
            this.inInteractiveSection = false;
            this.userRotating = false;
        }

        const targetPosition = new Vector3(this.coords.x, this.coords.y, this.coords.z);

        targetPosition.add(new Vector3(this.mouseOffset.x, this.mouseOffset.y));

        const MAX_EASING = 0.028;
        const ZOOMING_EASING = 0.2;

        const modelIsRotatable = this.inInteractiveSection && this.userRotating;
        const easing = modelIsRotatable ? 0 : !this.zoomedIn ? MAX_EASING : ZOOMING_EASING;
        this.camera.position.lerp(targetPosition, easing);
        // also need to lerp the focal point of the camera when we're zooming into a hotspot
        this.controls.target.lerp(this.cameraTarget, easing);
    }

    setDesktopCoords() {
        let sectionIdx = this.scrollContainer ? Math.round(this.scrollContainer.scrollTop / window.innerHeight) : 0;
        // console.log("SkateboardComponent -> setDesktopCoords -> sectionIdx", sectionIdx)

        // ASVT-151 - need to clamp this because it sometimes goes out of bounds
        sectionIdx = Math.max(0, Math.min(sectionIdx, this.content.length - 2));
        let sectionHeight = window.innerHeight;
        let prevCarPos = this.content[sectionIdx]['carPos'];
        let nextCarPos = this.content[sectionIdx + 1]['carPos'];

        let increments = {
            x: (nextCarPos.x - prevCarPos.x) / sectionHeight,
            y: (nextCarPos.y - prevCarPos.y) / sectionHeight,
            z: (nextCarPos.z - prevCarPos.z) / sectionHeight,
        }

        let scrollTop = this.scrollContainer ? this.scrollContainer.scrollTop : 0;
        let offset = scrollTop - sectionIdx * sectionHeight;
        let coords = {
            x: prevCarPos.x + (offset * increments.x),
            y: prevCarPos.y + (offset * increments.y),
            z: prevCarPos.z + (offset * increments.z),
        }

        return coords;
    }

    setCoords() {
        if (!this.currentSection) { return };

        if (this.deviceService.isLandscape()) {
            this.coords = this.setDesktopCoords();
        }
        else if (this.deviceService.isPortrait() && (this.deviceService.isDesktopSize() || this.deviceService.isTabletSize())) {
            this.coords = this.currentSection['carPos'];
        }
        else {
            this.coords = this.currentSection['mobileCarPos'];

        }


    }

    zoomIn(target, id) {
        this.zoomedIn = true;
        let onComplete = () => {
            this.shouldRender = false;
            this.router.navigate([], { queryParams: { hotspot: id }, queryParamsHandling: 'merge' });
        }
        // TODO fix this, we should really use a tween here
        setTimeout(onComplete, 700);
        if (target) {
            const position = this.getCenterPoint(target);
            this.cameraTarget = position;
            this.coords = this.camera.position.clone()
                .sub(position)
                .setLength(this.ZOOM_DISTANCE)
                .add(position);
        }

    }

    zoomOut() {
        this.shouldRender = true;
        this.zoomedIn = false;
        this.hotspotMouseOut(this.selectedHotspot);
        this.cameraTarget = new Vector3();
        this.setCoords();
    }

    updateControls() {
        if (!this.controls || this.deviceService.isMobile()) return; // preventing error in mobile affecting the hotspots

        this.controls.enabled = this.currentSection.id == 'landing';

        // this.controls.enabled = this.controls ? this.currentSection.id == 'landing' : null;
        // tiltToCamera clamps maxPolarAngle with a slight tilt to the camera so 
        // the floor texture doesn't disappear when it flattens out at θ:(Math.PI / 2)
        const tiltAmount = 0.125;
        this.controls.maxPolarAngle = (Math.PI / 2) - tiltAmount;
    }

    appendHotspots() {
        const outlineMeshes = this.getOutlineMeshes();
        const parentObjName = 'highlights';
        const HIDE_HOTSPOT_MARKERS = true;
        console.log(this.object);

        // TODO - this is a hack to work with all the duplicated content
        // this function now just gets called once onmodelload 
        this.hotspots.forEach(hotspotData => {

            this.object.traverse((child) => {
                // console.log("SkateboardComponent -> appendHotspots -> child.name", child.name)
                if (child.name === 'scene') {
                    this.skateboard3dObj = child;
                }

                if (child.name === 'car_shell6_2')
                    child.material.opacity = 0.06;

                let hotspotFound;
                if (hotspotData.group == child.name && child.parent.name == parentObjName)
                    hotspotFound = true;

                if (hotspotFound) {
                    const meshName = hotspotData.group;//.split('-').join('_');
                    hotspotData['outlineMesh'] = outlineMeshes[meshName];
                    if (!outlineMeshes[meshName]) {
                        console.warn('outline mesh not found!!!!')
                    }
                    if (!child) {
                        console.warn('positional mesh not found!!!!')
                    }
                    hotspotData['mesh'] = child;
                    child.parent.visible = !HIDE_HOTSPOT_MARKERS;
                }
            })
        });
    }

    getOutlineMeshes() {
        const outlineMeshes = [];
        const parentObjName = 'outlineMeshes';
        this.object.traverse((child) => {
            if (child.parent.name === parentObjName) {
                outlineMeshes[child.name] = child;
                child.visible = false;
            }
        });

        return outlineMeshes;
    }

    getCenterPoint(mesh) {
        const geometry = mesh.geometry;
        geometry.computeBoundingBox();
        const center = new Vector3();
        geometry.boundingBox.getCenter(center);
        mesh.localToWorld(center);
        return center;
    }

    updateHotspotPos() {
        for (let hotspot of this.currentHotspots) {
            if (hotspot['mesh']) {
                hotspot['proj'] = this.toScreenPosition(hotspot['mesh'], this.camera);
            }
        }
    }

    setCurrentHotspots() {
        this.currentHotspots = this.hotspots.filter((hotspot) => hotspot.section == this.currentSection.id && hotspot.popover !== '');
    }

    openHotspot(spot) {
        this.analyticsService.sendEvent('hotspot', this.currentSection.id, spot.group);
        this.zoomIn(spot.mesh, spot.group);
    }

    toScreenPosition(obj, camera) {
        var vector = new THREE.Vector3();
        var widthHalf = 0.5 * this.width;
        var heightHalf = 0.5 * this.height;

        // obj.updateMatrixWorld();
        vector.setFromMatrixPosition(obj.matrixWorld);
        vector.project(camera);

        vector.x = (vector.x * widthHalf) + widthHalf;
        vector.y = - (vector.y * heightHalf) + heightHalf;

        return { x: vector.x, y: vector.y };
    }

    hotspotMouseIn(hotspot) {
        this.selectedHotspot = hotspot;
        hotspot.hover = true;
        if (hotspot.outlineMesh) {
            this.sceneComposer.outlinePass.selectedObjects = [hotspot.outlineMesh];
            hotspot.outlineMesh.visible = true;
        }
        new TWEEN.Tween(this.renderer).to({ toneMappingExposure: .2 }, 300).start();
    }

    hotspotMouseOut(hotspot) {
        if(!hotspot) return;
        hotspot.hover = false;

        if (this.zoomedIn) return;
        hotspot.outlineMesh.visible = false;

        this.sceneComposer.outlinePass.selectedObjects = [];
        new TWEEN.Tween(this.renderer).to({ toneMappingExposure: this.TONE_MAPPING_EXPOSURE }, 300).start();
    }


    


    // ===================== Particles  ===================== //

    toggleParticles() {

        if (!this.particleEngine || !this.enableParticles) return;
        // Hard code - fix --TODO

        let softwareParams = [
            {section: 'advanced-safety', filter:'adv-safety-software'},
            {section: 'full-system-insights', filter: 'full-system-software'},
            {section: 'in-cabin-ux', filter: 'in-cabin-ux-software'}
        ]

        let displayParticles = softwareParams.find((d) => d.section == this.currentSection.id && this.params.filters?.includes(d.filter))

        this.showParticles = displayParticles;

        displayParticles ? this.particleEngine.fadeIn() : this.particleEngine.fadeOut();

    }

    // ===================== Backgrounds ===================== //
    async loadBackgroundVideos() {
        if (!this.showVideos) return;
        for (let section of this.content) {
            if (!section.video) continue;
            const bgVideo = new BGVideo(this.scene, this.camera, section);
            await bgVideo.init()
            this.videos[section.id] = bgVideo;
        }
    }

    enableBackground(params?) {
        // console.log(params);
        if (!this.showVideos) return;
        if (!this.videos[this.currentSection.id]) return;

        this.videos[this.currentSection.id].fadeIn();
    }

    async loadEvolutionImages() {
        this.evolutionOverlay = new EvolutionOverlay(this.scene, this.camera);
        await this.evolutionOverlay.load();
    }

    enableEvoSection() {
        if (this.evolutionOverlay && this.skateboard3dObj) {
            this.evolutionOverlay.gotoFilter(this.params);
            this.skateboard3dObj.visible = !this.evolutionOverlay.visible;
        }
    }

    /**
     * Safari and Chrome seem to have performance quirks.
     * Safari, rightly, prefers to use transform:translate3d
     * but this seems to tank performance in Chrome, which
     * prefers left,top.... using left, top causing pixel snapping
     * which looks ugly but performs better... sigh
     * @param hotspot 
     */
    getHotspotStyle(hotspot) {
        let style;
        // if (this.deviceService.isSafari()) {
            style = {
                transform: `translate3d(${hotspot.proj?.x}px, ${hotspot.proj?.y}px, 0px)`
            }
        // } else {
        //     style = {
        //         left: `${hotspot.proj?.x}px`,
        //         top: `${hotspot.proj?.y}px`
        //     }
        // }
        return style;
    }

}
