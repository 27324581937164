import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    links = [
        {label: 'Aptiv', url: 'https://www.aptiv.com/'},
        {label: 'Terms of Use', url: 'https://www.aptiv.com/terms-of-use'},
        {label: 'Contact Us', url: 'mailto:virtual-tools@aptiv.com?subject=Request%20for%20contact%20with%20an%20Aptiv%20Specialist&body=Please%20have%20an%20Aptiv%20Specialist%20contact%20me.'},
        {label: 'Connection Systems Catalog', url: 'https://ecat.aptiv.com/'},
        {label: 'Suppliers', url: 'https://www.aptiv.com/Suppliers'},
        {label: 'Legal & Compliance', url: 'https://www.aptiv.com/legal-compliance'},
        {label: 'Aptiv Privacy Statement', url: 'https://www.aptiv.com/privacy-statement'},
        {label: 'Cookie Policy', url: 'https://www.aptiv.com/cookie-policy'},
        {label: 'Do Not Sell My Personal Information', url: 'https://www.aptiv.com/contact-us/data-subject-request'}
    ]

    social = [
        {icon: 'facebook-icon.svg', url: 'https://facebook.com/aptiv'},
        {icon: 'vimeo-icon.svg', url: 'https://vimeo.com/aptiv'},
        {icon: 'youtube-icon.svg', url: 'https://www.youtube.com/aptiv'},
        {icon: 'medium-icon.svg', url: 'https://medium.com/@GlenDeVos'},
        {icon: 'instagram-icon.svg', url: 'https://instagram.com/aptiv'},
        {icon: 'twitter-icon.svg', url: 'https://twitter.com/aptiv'},
        {icon: 'linked-in-icon.svg', url: 'https://linkedin.com/company/aptiv'}
    ]

    constructor() { }

    ngOnInit(): void {
    }

}
