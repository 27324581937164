import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js/dist/tween.esm.js';
function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

class Point {

    alpha = 0;
    position = new THREE.Vector3();
    startPosition = new THREE.Vector3();
    speed;
    MAX_SPEED = 0.03;
    MIN_SPEED = 0.01;
    waveSpeed;
    waveHeight;
    waveAngle = Math.random();
    waveOffsetX = 0;

    constructor(){
        this.speed = getRandomArbitrary(this.MIN_SPEED, this.MAX_SPEED)/4;
        this.waveSpeed =  getRandomArbitrary(.04, .14)/3;
        this.waveHeight = getRandomArbitrary(.005, .006)*.5;
    }

    update(idx) {
        this.waveAngle+=this.waveSpeed;

        // this.position.y = this.startPosition.y + (Math.sin(this.waveAngle) * this.waveHeight);
        this.position.x = (this.position.x - this.speed) + this.waveOffsetX;
        this.position.z = this.startPosition.z + (Math.sin(this.waveAngle) * this.waveHeight);
        this.position.y = this.startPosition.y + (Math.cos(this.waveAngle) * this.waveHeight);

        // + (Math.sin(this.waveAngle) * this.waveHeight);
        if (this.position.x < ParticleEngine.CAR_BACK_END) {
            this.position.x = ParticleEngine.CAR_FRONT_START;
            this.alpha = 0;
        }

        let percentTravelled = Math.abs(this.position.x) / ParticleEngine.CAR_FRONT_START;
        // this.alpha = ParticleEngine.fade;
        // console.log("Point -> update -> ParticleEngine.fade", ParticleEngine.fade)
        this.alpha = Math.max(0, 1 - percentTravelled) * ParticleEngine.fade;
    }

}

export default class ParticleEngine {

    static readonly CAR_FRONT_MARGIN = .3;
    static readonly CAR_FRONT_X = 2.3;
    static readonly CAR_BACK_X = -2.3;

    static readonly CAR_FRONT_START = ParticleEngine.CAR_FRONT_X + ParticleEngine.CAR_FRONT_MARGIN;
    static readonly CAR_BACK_END = ParticleEngine.CAR_BACK_X - ParticleEngine.CAR_FRONT_MARGIN;


    static readonly CAR_LEFT_Z = -.885; //-.785
    static readonly CAR_RIGHT_Z = .185; //-.785
    
    static readonly CAR_TOP = 1.3;
    static readonly CAR_BOTTOM = .3;

    points;
    particleGeometry = new THREE.BufferGeometry();
    particleMesh = new THREE.Points();
    numParticles = 1000;
    
    fadeTween   : TWEEN.Tween;
    static fade = 0;

    positionBase = new THREE.Vector3(5, 0, 0);
    positionSpread = new THREE.Vector3(50, 1.8, 1);
    positionRadius = 2;
    particles = [];
    
    fShader = `
        uniform vec3 color;
        varying float vAlpha;
        void main() {
            gl_FragColor = vec4( color, vAlpha );
        }
    `
    vShader = `
        attribute float alpha;
        varying float vAlpha;
        void main() {
            vAlpha = alpha;
            vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
            gl_PointSize = 4.0;
            gl_Position = projectionMatrix * mvPosition;
        }
    `

    shaderMaterial = new THREE.ShaderMaterial({
        uniforms: { color: { value: new THREE.Color(0x3bc6eb) }, opacity: { value: 1 } },
        vertexShader: this.vShader,
        fragmentShader: this.fShader,
        transparent: true,

    });


    init(scene) {
        const vertices = [];
        const alphas = new Float32Array(this.numParticles);

        for (let i = 0; i < this.numParticles; i++) {
            let particle = this.createParticle();
            
            vertices.push(particle.position.x, particle.position.y, particle.position.z);
            alphas[i] = particle.alpha;
            this.particles.push(particle);
        }

        this.particleGeometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
        this.particleGeometry.setAttribute('alpha', new THREE.BufferAttribute(alphas, 1));
        this.shaderMaterial.blending = THREE.AdditiveBlending;
        
        this.points = new THREE.Points(this.particleGeometry, this.shaderMaterial);
        this.points.name = 'particles';
        scene.add(this.points);
    }

    randomValue(base, spread) {
        return base + spread * (Math.random() - 0.5);
    }

    randomVector3(base, spread) {
        var rand3 = new THREE.Vector3(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5);
        return new THREE.Vector3().addVectors(base, new THREE.Vector3().multiplyVectors(spread, rand3));
    }

    createParticle() {

        let particle = new Point();

        // particle.position.y = this.CAR_BOTTOM;
        // particle.position.z = this.CAR_LEFT_Z;    
        // particle.position.x = this.CAR_FRONT_X;

        particle.position.x = getRandomArbitrary(ParticleEngine.CAR_FRONT_START, ParticleEngine.CAR_BACK_END);
        particle.position.y = getRandomArbitrary(ParticleEngine.CAR_BOTTOM, ParticleEngine.CAR_TOP);
        particle.position.z = getRandomArbitrary(ParticleEngine.CAR_RIGHT_Z, ParticleEngine.CAR_LEFT_Z);
        particle.startPosition = particle.position;

        particle.alpha = 0;
        return particle;
    }

    update(dt) {
        let positions = this.points.geometry.attributes.position.array;
        let alphas = this.points.geometry.attributes.alpha.array;

        for (let [idx, particle] of this.particles.entries()) {
            particle.update(idx);
            
            positions[idx * 3] = particle.position.x;
            positions[(idx * 3)+1] = particle.position.y;
            positions[(idx * 3)+2] = particle.position.z;

            alphas[idx] = particle.alpha;
        }

        this.points.geometry.attributes.position.needsUpdate = true;
        this.points.geometry.attributes.alpha.needsUpdate = true;
    }


    fadeOut() {
        this.doFadeTween(0);
    }
    
    fadeIn() {
        this.points.visible = true;
        this.doFadeTween(1);
    }

    doFadeTween(opacity){
        if(this.fadeTween)
            this.fadeTween.stop();
    
        const fadeTime = 300;
        this.fadeTween = new TWEEN.Tween(ParticleEngine)
            .to({fade:opacity}, fadeTime)
            .onComplete( ()=> {
                this.points.visible = opacity === 0 ? false : true;
            })
            .start();
    }

}


