import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js/dist/tween.esm.js';

import { Mesh, MeshBasicMaterial, VideoTexture } from 'three';
import filters from '../../../assets/json/filters.json';

class EvoTexture {
    
    id;
    plane;
    mesh;
    fadeTween;

    constructor(){
    }

    async getImageObject(id){
        this.id = id;
        let texture = await new THREE.TextureLoader()
            .setCrossOrigin('use-credentials')
            .loadAsync( `./assets/images/${id}.png` );

        let scale = .0020;
        let geometry = new THREE.PlaneGeometry(800*scale, 642*scale);
        let material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.FrontSide, transparent: true, opacity: 0 });
        texture.encoding = THREE.sRGBEncoding;

        material.depthWrite = false;
        material.depthTest = false;

        var meshObject = new THREE.Mesh(geometry, material);
        // meshObject.rotation.set( 1.5, 3.14, 0 );
        meshObject.position.set( -0.07, 0.07, -3);
        meshObject.renderOrder = 0;
        // meshObject.scale.setScalar(5);

        this.mesh = meshObject;
        this.plane = geometry;
    }

    fadeIn(){
        this.mesh.visible = true;
        let onComplete = () => {}
        this.doFadeTween(this.mesh, 1, onComplete);
    }

    fadeOut(){
        (this.mesh.material as MeshBasicMaterial).transparent = true;
        let onComplete = () => {
            this.mesh.visible = false;
        }
        this.doFadeTween(this.mesh, 0, onComplete);
    }

    doFadeTween(obj, opacity, onComplete){
        if(this.fadeTween)
            this.fadeTween.stop();
        const fadeTime = 1000;
        this.fadeTween = new TWEEN.Tween(obj.material)
            .to({opacity:opacity}, fadeTime)
            .onComplete(onComplete)
            .start();
    }
}

export default class EvolutionOverlay {

    visible = false;;
    meshes = [];
    container: THREE.Group;
    currentMesh:EvoTexture;
    filters;

    constructor(private scene, private camera){
    }

    async load(){
        this.container = new THREE.Group();
        this.container.renderOrder = 10;
        this.container.name = `EvolutionOverlay`;
        this.camera.add(this.container);

        // don't include future
        this.filters = filters.filter( f => f.section == 'evolution' && f.id != 'evolution-tomorrow');
        // this.filters = filters.filter( f => f.section == 'evolution' );

        for (let i = 0; i < this.filters.length; i++) {
            let mesh = new EvoTexture();
            await mesh.getImageObject(this.filters[i].id);  
            this.meshes.push(mesh);
            this.container.add(mesh.mesh);
        }
    }


    gotoFilter(params) {
        if(params.section != 'evolution' || params.filters == undefined){
            return this.deselect();
        }

        let filtername = params.filters.toString();
        console.log("EvolutionOverlay -> gotoFilter -> filtername", filtername);

        let visible = false, selectedFilter;
        for (let i = 0; i < this.filters.length; i++) {
            if (filtername.indexOf(this.filters[i].id) != -1){
                visible = true;
                selectedFilter = this.filters[i].id;
            }  
        }

        this.deselect();
        
        for (let i = 0; i < this.meshes.length; i++) {
            if(this.meshes[i].id == selectedFilter){
                this.meshes[i].fadeIn();
                this.currentMesh = this.meshes[i];
            }
        }

        // this.setVisibilty(this.currentMesh != null);
        this.visible = this.currentMesh != null;
    }

    deselect(){
        if(this.currentMesh){
            this.currentMesh.fadeOut();
            this.currentMesh = null;
        }
        this.visible = false;
    }

}