import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { CineonToneMapping } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

enum LoadState {
    NOT_LOADED  = 'NOT_LOADED',
    LOADING     = 'LOADING',
    LOADED      = 'LOADED',
    ABORTING    = 'ABORTING',
    ABORTED     = 'ABORTED',
    UNLOADED    = 'UNLOADED',
    DISPOSED    = 'DISPOSED'
}

@Component({
    selector: 'gltf-object',
    templateUrl: './gltf-object.component.html',
    styleUrls: ['./gltf-object.component.scss']
})
export class GltfObjectComponent {

    loadState:LoadState = LoadState.NOT_LOADED;
    readonly TONE_MAPPING_EXPOSURE = 1;

    static _sharedRenderer;
    filePath: string;
    loader: GLTFLoader;
    scene: THREE.Scene;
    camera: THREE.PerspectiveCamera;
    renderer: THREE.WebGLRenderer;
    controls: OrbitControls;

    width;
    height;
    object;

    constructor() {
    }

    async init() {
        this.renderer = this.setRenderer();
        this.scene = new THREE.Scene();
        this.scene.name = `GltfObjectComponent`;
        this.scene.position.set(0, 0, 0);
        this.camera = this.setCamera();
        this.camera.setViewOffset(this.width, this.height * 1.2, 0, 0, this.width, this.height);
        this.controls = this.setControls();
    }

    async loadEnvMap() {

        const imgPath='./assets/images/envmap/lightroom_14b.hdr';

        const texture = await new RGBELoader()
            .setDataType( THREE.UnsignedByteType )
            .loadAsync(imgPath);

        var pmremGenerator = new THREE.PMREMGenerator( this.renderer );
        pmremGenerator.compileEquirectangularShader();
        var envMap = pmremGenerator.fromEquirectangular( texture ).texture;

        this.scene.environment = envMap;
        // useful to see the img as a sky box background for debugging reflections
        // this.scene.background = envMap;
        
        texture.dispose();
        pmremGenerator.dispose();
        // this.setLights();
    }

    setCamera() {
        let camera = new THREE.PerspectiveCamera(45, this.width / this.height, 1, 1000);
        this.scene.add(camera);
        return camera;
    }

    setLights() {
        // LIGHTS
        const scene = this.scene;
        let hemiLight = new THREE.HemisphereLight( 0xff0000, 0xff0000, 0.7 );
        hemiLight.color.setHSL( 0.6, 1, 0.6 );
        hemiLight.groundColor.setHSL( 0.095, 1, 0.75 );
        hemiLight.position.set( 0, 50, 0 );
        scene.add( hemiLight );

        let dirLight = new THREE.DirectionalLight( 0xff0000, .7 );
        dirLight.color.setHSL( 0.1, 1, 0.95 );
        dirLight.position.set( - 2, 1.75, 2 );
        dirLight.position.multiplyScalar( 30 );
        scene.add( dirLight );
    }

    setGridHelper(scene) {
        let size = 200;
        let divisions = 20;

        let gridHelper = new THREE.GridHelper(size, divisions);
        scene.add(gridHelper);
    }

    setRenderer() {
        //TODO - alpha need to be true right now so that both the words and the hotspots can have user interaction 
        let renderer = new THREE.WebGLRenderer({ alpha: false, antialias: false });
        renderer.setSize(this.width, this.height);
        console.log(this.width, this.height)
        renderer.toneMapping = CineonToneMapping;
        renderer.toneMappingExposure = this.TONE_MAPPING_EXPOSURE;
        renderer.outputEncoding = THREE.sRGBEncoding;

        renderer.setPixelRatio(window.devicePixelRatio);
        return renderer;
    }

    setControls() {
        let controls = new OrbitControls(this.camera, document.body);
        controls.enableZoom = false;
        controls.enableKeys = false;
        controls.enableDamping = true;
        controls.dampingFactor = .1;
        controls.enablePan = false;
        return controls;
    }

    setLoaders() {
        let loader = new GLTFLoader();
        loader.setCrossOrigin('use-credentials');
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('./assets/libs/draco/');
        loader.setDRACOLoader(dracoLoader);
        return loader;
    }

    async loadModel(file, callback = null) {
        this.filePath = file;
        console.log("GltfObjectComponent -> loadModel -> this.loadState", this.loadState)
        if(this.loadState === LoadState.LOADED || this.loadState === LoadState.LOADING){
            throw new Error('Cant call loadModel if component is loaded or loading');
        }

        this.loadState = LoadState.LOADING;
        this.loader = this.setLoaders();
        this.loader.load (
            this.filePath, 
            (gltf) => {
                console.log(gltf)
                this.onLoad(gltf, callback)
            }, 
            this.onLoadProgress.bind(this), 
            this.onLoadError.bind(this)
        )
    }

    onLoad(gltf, callback = null){
        if(this.loadState == LoadState.DISPOSED){
            this.disposeObject3d(gltf.scene);
            return;
        } 

        this.scene.name = `GltfObjectComponent::${this.filePath}`;
        this.object = gltf.scene;
        this.scene.add(this.object);
        this.loadState = LoadState.LOADED;
        if (callback) callback(gltf);
    }

    onLoadProgress(progressEvent:ProgressEvent){
        if(this.loadState === LoadState.ABORTING || this.loadState === LoadState.DISPOSED){
            console.log('Aborting load' + this.filePath);
            (progressEvent.currentTarget as any).abort();
            return;
        }

        if(progressEvent.type === 'progress'){
            // console.log((progressEvent.loaded / progressEvent.total * 100) + '% loaded ' + this.filePath);
        }
    }

    onLoadError(errorEvent:ErrorEvent){
        if(errorEvent.type === 'abort'){
            console.info(`GltfObjectComponent model load ABORTED ${this.filePath}`);
        } else {
            // throw errorEvent;
            console.log(errorEvent);
        }
    }

    abort(){
        this.loadState = LoadState.ABORTING;
        this.loader.dracoLoader.dispose();
    }

    unload(){
    }

    dispose(){
        if(this.loadState == LoadState.LOADING){
            this.abort();
        }

        this.loadState = LoadState.DISPOSED;
        this.disposeObject3d(this.scene);
        this.controls.dispose();
        this.renderer.dispose();
        this.renderer.forceContextLoss();

        this.controls   = null;
        this.scene      = null;
        this.renderer   = null;
        this.loader     = null;
        this.camera     = null;
    }

    /**
     * https://stackoverflow.com/questions/30359830/how-do-i-clear-three-js-scene/48722282
     * @param obj 
     */
    disposeObject3d(obj){
        // console.log("GltfObjectComponent -> disposeObject3d -> obj", obj);

        while(obj.children.length > 0){ 
          this.disposeObject3d(obj.children[0])
          obj.remove(obj.children[0]);
        }
        if(obj.geometry) obj.geometry.dispose()
      
        if(obj.material){ 
          //in case of map, bumpMap, normalMap, envMap ...
          Object.keys(obj.material).forEach(prop => {
            if(!obj.material[prop])
              return         
            if(obj.material[prop] !== null && typeof obj.material[prop].dispose === 'function')                                  
              obj.material[prop].dispose()                                                        
          })
          obj.material.dispose()
        }
        if(obj.type !== 'Scene' && obj.dispose){
                console.log('trying to dispose obj');                
                console.log(obj.dispose);
                obj.dispose();
        }
    }   

}
