<div #anchor class='skateboard' ></div>
<div class='hotspot-container' *ngIf='!zoomedIn'>

    <div *ngFor='let hotspot of currentHotspots' 
        [style]='getHotspotStyle(hotspot)'
        class='skateboard__hotspot' 
        [ngClass]='{active: hotspot.active, hover: hotspot.hover, software: hotspot.group == "software"}'
        (click)='openHotspot(hotspot)' 
        (mouseenter)='hotspotMouseIn(hotspot)' 
        (mouseleave)='hotspotMouseOut(hotspot)'>
 
        <div class='hotspot__spot'></div>
        <div class='hotspot__popover' class='{{hotspot.textAlignment}}' >
            <div class='hotspot__text' >{{hotspot.popover}}</div>
        </div>
    </div>

</div>

<div class='skateboard__feathering'></div>
<!-- <app-evolution-overlay></app-evolution-overlay> -->