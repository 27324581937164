import { Component, AfterViewInit, ElementRef, OnChanges, Input, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { GltfObjectComponent } from '../gltf-object/gltf-object.component';
import { DeviceService } from 'src/app/services/device.service';
import '@google/model-viewer';

@Component({
    selector: 'app-part-model',
    templateUrl: './part-model.component.html',
    styleUrls: ['./part-model.component.scss']
})
export class PartModelComponent implements AfterViewInit, OnChanges, OnDestroy {

    @Input() filePath: string;
    @Input() showPoster;
    @ViewChild('anchor') anchor: ElementRef;
    @Input() content: any;

    modelViewer;

    attrs = {
        ar: false,
        autoplay: true,
        src: '',
        'camera-controls': true
    }

    loading: boolean;
    destroyed: boolean;
    clock: THREE.Clock;
    model: GltfObjectComponent;

    constructor(public elRef: ElementRef, public deviceService: DeviceService) {
    }

    ngOnChanges(changes) {
        if (changes.filePath) {
            this.loadModelWithTimeout();
        }
    }

    ngAfterViewInit() {
        this.loadModelWithTimeout();
    }

    loadModelWithTimeout() {
        setTimeout(this.loadModel.bind(this), 200);
    }

    ngOnDestroy() {
        if (this.model) this.model.dispose();
        this.destroyed = true;
    }

    onImageLoad() {
        this.loading = false;
    }

    loadModel() {
        if (!this.anchor) return;
        console.log("PartModelComponent -> loadModel -> this.filePath", this.content)
        this.createModelViewer(this.filePath);
        this.loading = true;
    }

    destroyModelViewer() {
        this.anchor.nativeElement.removeChild(this.modelViewer);
        this.modelViewer = null;
    }

    loadNewModel(filename){
        console.log("PartModelComponent -> createModelViewer -> this.content", this.content)
        this.modelViewer.resetInteractionPrompt();
        this.modelViewer.setAttribute('src', filename);
    }

    getCameraTargetFromContent(){
        // default camera target should be auto auto auto
        const offsetX = this.content.posX || 'auto';
        const offsetY = this.content.posY || 'auto';
        const offsetZ = this.content.posZ || 'auto';

        let  cameraTarget = `${offsetX} ${offsetY} ${offsetZ}`;
        return cameraTarget;
    }

    createModelViewer(filename) {
        // if model viewer already exists load model into existing instance
        if (this.modelViewer) {
            this.loadNewModel(filename);
            return;
        }

        this.modelViewer = document.createElement('model-viewer') as any;
        this.modelViewer.modelCacheSize = 0;
        this.anchor.nativeElement.appendChild(this.modelViewer);
        this.attrs.src = filename;

        const FOV = this.content.FOV || 'auto';
        console.log("PartModelComponent -> createModelViewer -> FOV", FOV)
        this.attrs['field-of-view'] = FOV
        this.attrs['min-field-of-view'] = FOV
        this.attrs['max-field-of-view'] = FOV
        this.attrs['camera-orbit'] = '45deg 55deg';
        this.attrs['camera-target'] = this.getCameraTargetFromContent();
        
        for (let [key, value] of Object.entries(this.attrs)) {
            this.modelViewer.setAttribute(key, value);
        }
        this.addModelEventListeners();
        // this.addPanAndCenteringListeners();
    }

    addModelEventListeners() {
        // example of using the load handler
        this.modelViewer.addEventListener('scene-graph-ready', (e) => {
            console.log('scene-graph-ready');
        });

        this.modelViewer.addEventListener('progress', (e) => {
            // if we want to add a percentage progress bar we would do so here
            if (e.detail.totalProgress == 1) this.onLoad();
            // console.log('progress');
        });
        
        this.modelViewer.addEventListener('load', (e) => {
            // access shadowroot and hide blue focus outline
            const selectors = this.modelViewer.shadowRoot.querySelector('.userInput');
            selectors.style.outline = 'none';
            // console.log('load');
        });
    }

    onLoad() {
        // add a small timer to fade the annoying flash/fade in animation
        setTimeout(() => {
            this.setModelSizeToElement();
            const modelStartPosition = {
                x: 35,
                y: 60 //90 degrees is side on
            }
            if(this.content.isSoftware){
                modelStartPosition.x = -35;
                modelStartPosition.y = 60;
            }

            this.modelViewer.cameraOrbit = `${modelStartPosition.x}deg ${modelStartPosition.y}deg`;
            const FOV = this.content.FOV || 'auto';
            this.modelViewer.setAttribute('field-of-view', FOV);
            this.modelViewer.setAttribute('max-field-of-view', FOV);
            this.modelViewer.setAttribute('min-field-of-view', FOV);
            this.modelViewer.setAttribute('camera-target', this.getCameraTargetFromContent());
            console.log("PartModelComponent -> onLoad -> FOV", FOV);
            
            this.loading = false;
        }, 600);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if(this.modelViewer) this.setModelSizeToElement();
    }

    setModelSizeToElement() {
        this.setModelSize(this.elRef.nativeElement.clientWidth, this.elRef.nativeElement.clientHeight);
    }

    setModelSize(width, height) {
        this.modelViewer.style.width = `${width}px`;
        this.modelViewer.style.height = `${height}px`;
    }

    /**
     * This is taken from the model-viewer examples. It allows
     * users to center the model on a new pivot point and pan the
     * model. We're using it for getting the initial positions of
     * the model so they're nicely centered after the models animate open
     */
    addPanAndCenteringListeners(){

        // const modelViewer = document.querySelector('#pan-demo');
        const tapDistance = 2;
        let panning = false;
        let panX, panY;
        let startX, startY;
        let lastX, lastY;
        let metersPerPixel;

        const startPan = () => {
            const orbit = this.modelViewer.getCameraOrbit();
            const { theta, phi, radius } = orbit;
            metersPerPixel = 0.75 * radius / this.modelViewer.getBoundingClientRect().height;
            panX = [-Math.cos(theta), 0, Math.sin(theta)];
            panY = [
                -Math.cos(phi) * Math.sin(theta),
                Math.sin(phi),
                -Math.cos(phi) * Math.cos(theta)
            ];
            this.modelViewer.interactionPrompt = 'none';
        };

        const movePan = (thisX, thisY) => {
            const dx = (thisX - lastX) * metersPerPixel;
            const dy = (thisY - lastY) * metersPerPixel;
            lastX = thisX;
            lastY = thisY;

            const target = this.modelViewer.getCameraTarget();
            // target.x += dx * panX[0] + dy * panY[0];
            // target.y += dx * panX[1] + dy * panY[1];
            target.z += dx * panX[2] + dy * panY[2];
            this.modelViewer.cameraTarget = `${target.x}m ${target.y}m ${target.z}m`;
        };

        const recenter = (pointer) => {
            panning = false;
            if (Math.abs(pointer.clientX - startX) > tapDistance ||
                Math.abs(pointer.clientY - startY) > tapDistance)
                return;
            const rect = this.modelViewer.getBoundingClientRect();
            const x = (event as any).clientX - rect.left;
            const y = (event as any).clientY - rect.top;
            const hit = this.modelViewer.positionAndNormalFromPoint(x, y);
            this.modelViewer.cameraTarget =
                hit == null ? 'auto auto auto' : hit.position.toString();
            
            if(hit){
                console.log(this.content.model,  hit.position.toString().split(' ').join(' \t'));
            }
        };

        this.modelViewer.addEventListener('mousedown', (event) => {
            startX = (event as any).clientX;
            startY = (event as any).clientY;
            panning = (event as any).button === 2 || (event as any).ctrlKey || (event as any).metaKey ||
                (event as any).shiftKey;
            if (!panning)
                return;

            lastX = startX;
            lastY = startY;
            startPan();
            event.stopPropagation();
        }, true);

        this.modelViewer.addEventListener('touchstart', (event) => {
            startX = (event as any).touches[0].clientX;
            startY = (event as any).touches[0].clientY;
            panning = (event as any).touches.length === 2;
            if (!panning)
                return;

            const { touches } = (event as any);
            lastX = 0.5 * (touches[0].clientX + touches[1].clientX);
            lastY = 0.5 * (touches[0].clientY + touches[1].clientY);
            startPan();
        }, true);

        this.modelViewer.addEventListener('mousemove', (event) => {
            if (!panning)
                return;

            movePan((event as any).clientX, (event as any).clientY);
            event.stopPropagation();
        }, true);

        this.modelViewer.addEventListener('touchmove', (event) => {
            if (!panning || (event as any).touches.length !== 2)
                return;

            const { touches } = (event as any);
            const thisX = 0.5 * (touches[0].clientX + touches[1].clientX);
            const thisY = 0.5 * (touches[0].clientY + touches[1].clientY);
            movePan(thisX, thisY);
        }, true);

        self.addEventListener('mouseup', (event) => {
            recenter(event);
        }, true);

        self.addEventListener('touchend', (event) => {
            if (event.touches.length === 0) {
                recenter(event.changedTouches[0]);
            }
        }, true);

    }



}


