import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { ErrorComponent } from './pages/error/error.component';
import { GuardService } from './services/guard.service';

const routes: Routes = [
    {
        path: 'model-viewer-no-ar-fallback',
        component: ErrorComponent
    },
    {
        path: '',
        component: MainComponent, 
        canActivate: [GuardService],
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
