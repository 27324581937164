import { AfterViewInit, Component, HostBinding, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import filters from '../../../assets/json/filters.json';
import content from '../../../assets/json/content.json';
import hotspots from '../../../assets/json/hotspots.json';

import { DeviceService } from '../../services/device.service';
import { ListenerService } from 'src/app/services/listener.service';
import { NavbarComponent } from 'src/app/components/navbar/navbar.component';


@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements AfterViewInit {

    @ViewChild( NavbarComponent, { read: ElementRef }) navRef;

    @HostBinding('style.height') height;
    @HostBinding('style.width') width;

    learnModal;
    hotspotModal;

    filters = filters;
    content = content;
    hotspots = hotspots;

    loading = true;
    visibleFilters;
    sectionId;
    sectionContent;
    transition;
    logoIsHidden;
    footerIsHidden = true;


    
    constructor(private route: ActivatedRoute, private router: Router, public deviceService: DeviceService, private elRef: ElementRef, private listenerService: ListenerService) {
        this.resizeHandler();

        this.listenerService.initResizeListeners(this.resizeHandler.bind(this));

        this.route.queryParams.subscribe((params) => this.handleParamChange(params));

    }

    ngAfterViewInit() {
        this.initScrollLogic();
    }


    handleParamChange(params) {
        document.body.style.overflowY = params.ar || params.learn || params.hotspot ? 'hidden' : 'visible';

        this.sectionId = params.section;
        this.sectionContent = this.content.find((c) => c.id == params.section);


        this.transition = this.transition == 'slide-up-out' ? 'slide-up-in' : 'slide-down-in';

        this.learnModal = params.learn;
        this.hotspotModal = params.hotspot;
        this.setFilters(params.filters, params.section);
    }

    initScrollLogic() {
        if (this.deviceService.touchIsEnabled() && this.deviceService.isPortrait()) {
            let refs = [this.elRef?.nativeElement].filter(Boolean);
            this.listenerService.initHammer(refs, this.handleSwipe.bind(this))
        }
 

        if (this.deviceService.isPortrait()) {
            this.listenerService.initWheel(this.handleSwipe.bind(this));
        }
        else {
            this.listenerService.removeWheel();
        }

    }


    resizeHandler() {
        this.height = `${window.innerHeight}px`;
        this.width = `${window.innerWidth}px`;

        this.initScrollLogic();
        this.footerIsHidden = true;
        this.logoIsHidden = false;
    }
    

    setFilters(selectedFilters, section) {
        for (let filter of this.filters) {
            filter['active'] = selectedFilters?.includes(filter.id);

        }
        for (let hotspot of this.hotspots) {
            hotspot['active'] = selectedFilters?.includes(hotspot.category);
        }

        this.visibleFilters = this.filters.filter((filter) => filter.section == section);

    }


    updateFilters() {
        let activeFilters = this.filters.filter((category) => category['active']);
        let ids = activeFilters.map((filter) => filter.id)
        this.router.navigate([], { queryParams: { filters: ids }, queryParamsHandling: 'merge' })
    }


    isDescendant(parent, child) {
        var node = child?.parentNode;
        while (node) {
            if (node == parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
   }


    handleSwipe(e) {
        console.log('swipe')
        if (this.hotspotModal || this.learnModal || this.loading) return;

        e.srcEvent?.preventDefault();

        if (this.isDescendant(this.navRef.nativeElement, e.srcEvent?.target)) return

        if (e.type == 'wheel') {
            var swipe = e.deltaY < 0 ? 'down' : 'up';
        }
        else if (this.deviceService.isAndroid()) {
            var swipe = e.velocityY < 0 ? 'up' : 'down';
        }
        else {
            var swipe = e.deltaY > 0 ? 'down' : 'up';
        }


        let pageMovement = this.controlNavLogo(swipe);
        if (pageMovement) this.controlPage(swipe);

    }

    controlNavLogo(swipe) {

        if (swipe == 'down' && !this.footerIsHidden) {
            this.footerIsHidden = true;
        }

        else if (swipe == 'down' && this.logoIsHidden && this.deviceService.isPortrait()) {
            this.logoIsHidden = false;
        }

        else if (swipe == 'up' && !this.logoIsHidden && this.deviceService.isPortrait()) {
            this.logoIsHidden = true;
        }

        else if (swipe == 'up' && this.footerIsHidden && this.sectionId == this.content[this.content.length - 1].id) {
            this.footerIsHidden = false;
        }
        else return true;
    }

    controlPage(swipe) {
        let idx = this.content.findIndex((c) => c['active']);
        let nextSection;

        if (swipe == 'up' && idx == this.content.length - 1 || swipe == 'down' && idx == 0) {
            return
        }
        else if (swipe == 'up') {
            nextSection = this.content[idx + 1]
        }
        else {
            nextSection = this.content[idx - 1]
        }


        this.goToSection({ id: nextSection.id, swipe });

    }

    //mobile only
    goToSection(data) {
        this.transition = data.swipe == 'up' || !data.swipe ? 'slide-up-out' : 'slide-down-out';
        setTimeout(() => this.router.navigate([''], { queryParams: { section: data.id }, queryParamsHandling: 'merge' }), 500);
    }


    @HostListener('window:scroll')
    handleModelPos() {
        if (window.scrollY > (this.content.length - 1) * window.innerHeight) {
            this.footerIsHidden = false;

        }
        else {
            this.footerIsHidden = true;
        }
    }

}
