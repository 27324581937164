<div class='footer__container'>
    <div class='footer__hr'></div>
    <div class='footer__content'>
        <img class='footer__logo' src='./assets/images/aptiv-logo.svg' />
        <div class='footer__social'>
            <a *ngFor='let s of social' href='{{s.url}}' target='_blank' class='footer__channel'>
                <img src='./assets/images/{{s.icon}}' />
            </a>
        </div>
    </div>
    <div class='footer__links'>
        <a class='footer__link' *ngFor='let link of links' href='{{link.url}}' target='_blank'>
            {{link.label}}
            <img src='./assets/images/external-link-icon.svg' />
        </a>
    </div>
    <div class='footer__legal'>© 2020 Aptiv. All rights reserved</div>

</div>