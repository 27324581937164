import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

    constructor(private route: ActivatedRoute, private router: Router) { }

    ngOnInit(): void {
    }

    goHome() {
        this.router.navigate(['/']);
    }

}
