import { Component, HostListener, ElementRef, AfterViewInit, HostBinding, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceService } from 'src/app/services/device.service';


// import * as Hammer from 'hammerjs';


import content from '../../../assets/json/content.json';
import filters from '../../../assets/json/filters.json';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit {

    @ViewChild('navbarLinks') navbarLinks;

    @Input() logoIsHidden;
    @Output() mobileNavEmitter = new EventEmitter();

    filters = filters;
    content = content;

    height;
    currentSection;
    currentSectionIdx;
    paramFilters;
    links;
    scrollContainer: HTMLElement;
    
    constructor(private router: Router, private route: ActivatedRoute, private el: ElementRef, private deviceService: DeviceService) {
        route.queryParams.subscribe((params) => {
            this.currentSection = params.section;
            this.currentSectionIdx = content.findIndex((s) => s.id == params.section);
            this.paramFilters = Array.isArray(params.filters) ? params.filters : [params.filters];

            this.setActiveLink(params.section);
            this.scrollToPos(params.section);
            this.links = this.content.filter((link) => !!link.navigation);
            this.setLinksPos();
      
            
        });

        window.addEventListener('resize', this.ngAfterViewInit.bind(this))
    }

    ngAfterViewInit() {
        this.scrollContainer = document.getElementById('scroll-container');
        this.scrollContainer.addEventListener('scroll', this.getSection.bind(this));

        this.height = this.el.nativeElement.clientHeight;
        this.setLinksPos();
        this.scrollToPos(this.currentSection);
    }


    @HostBinding('style.marginTop.px')
    get topOffset() {
        return this.logoIsHidden && this.deviceService.isPortrait() ? -50 : 0;
    }

    setLinksPos() {
        
        if (!this.navbarLinks || !this.deviceService.isMobileSize()) return;

        else if (this.currentSection == 'landing') {
            this.navbarLinks.nativeElement.scrollTo({left: 0, behavior: 'smooth'})
            return;
        }
        else {
            let link = document.getElementById('link-' + this.currentSection);
            let width = link.clientWidth;
            let offset = link.offsetLeft;
    
            this.navbarLinks.nativeElement.scrollTo({left: offset - window.innerWidth/2 + width/2, behavior: 'smooth'});

        }
    }

    
    setNavFade() {
        let idx = this.content.findIndex((link) => link['active']);
        if (idx == 0 || idx == 1) {
            return 'hide-left';
        }
        else if (idx == this.content.length -1 ) {
            return 'hide-right';
        }
    }

    setActiveLink(fragment) {
        for (let link of this.content) {
            link['active'] = link.id === fragment;
        }
    }

    scrollToPos(fragment) {
        let elem = document.getElementById(fragment);


        if (elem && !this.windowIsInSection(fragment)) {
            this.scrollContainer.scrollTo({top: elem.offsetTop, behavior: 'smooth'});
        }
    }

    windowIsInSection(anchor) {
        let offset = window.innerHeight / 2;
        let elem = document.getElementById(anchor);

        const scrollY = this.scrollContainer.scrollTop;
        return elem && scrollY + offset >= elem.offsetTop && scrollY + offset < elem.offsetTop + elem.clientHeight;

    }


    getSection(e) {
        for (let link of content) {
            if (this.windowIsInSection(link.id) && !link['active']) {
                this.router.navigate([''], { queryParams: { section: link.id }, queryParamsHandling: 'merge' })
                break;
            }
        }
    }

    goToSection(id) {
        let filters = this.handleFilters(id);
        this.handleNavigation(id, filters); 
    }

    handleNavigation(id, filters) {
        if (this.deviceService.isPortrait()) {
            let newSectionIdx = content.findIndex((c) => c.id == id);
            let swipe = newSectionIdx > this.currentSectionIdx ? 'up' : 'down';
            
            
            this.router.navigate([''], { queryParams: { filters: filters }, queryParamsHandling: 'merge' });
            
            if (newSectionIdx != this.currentSectionIdx) this.mobileNavEmitter.emit({id, swipe});
            return;
        }
        else {
            
            this.router.navigate([''], { queryParams: { filters: filters }, queryParamsHandling: 'merge' });
            this.scrollToPos(id);
        }
    }

    handleFilters(id) {
        let filterToRemove = this.filters.find((filter) => this.paramFilters.includes(filter.id) && filter.section == id);
        
        let filters = this.paramFilters.slice();

        if (filterToRemove) {
            let idx = this.paramFilters.findIndex((filter) => filter == filterToRemove.id);
            filters.splice(idx, 1);
        }
        return filters;
    }


}
