import { Component, OnInit, Input, HostListener } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
    selector: 'app-promo',
    templateUrl: './promo.component.html',
    styleUrls: ['./promo.component.scss']
})
export class PromoComponent implements OnInit {

    @Input() content;

    constructor(private analyticsService: AnalyticsService) { }

    ngOnInit(): void {
    }

    openLink() {
        this.analyticsService.sendEvent('media', this.content.id, this.content.link);
        window.open(this.content.link, '_blank');
    }

}
