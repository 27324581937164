<div class='feature__block'>
    <div class='feature__header'>{{content.learnMoreLeftHeader}}</div>
    <a class='feature__cta btn' href='{{content.learnMoreLeftLink}}' target='_blank'>
        {{content.learnMoreLeftCTA}}
        <img src='./assets/images/new-window-icon.png' />
    </a>
</div>
<div class='feature__block'>
    <div class='feature__header'>{{content.learnMoreRightHeader}}</div>
    <a class='feature__contact btn__pill orange-hollow' href='https://www.aptiv.com/contact-us' target='_blank'>
        Contact Us
        <img src='./assets/images/arrow-right-orange.svg' />
    </a>
</div>