<!-- Navbar -->
<app-navbar [logoIsHidden]='logoIsHidden' (mobileNavEmitter)='goToSection($event)' [ngClass]='{transparent: hotspotModal, footer: !footerIsHidden}'></app-navbar>

<!-- Landscape -->
<div class="scroll-container app__sections" id='scroll-container' *ngIf='deviceService.isLandscape()' [ngClass]='{transparent: hotspotModal, "windows-chrome": deviceService.isWindowsChrome()}'>
    <app-section class='app__section' *ngFor='let section of content; let i = index' [content]='section' id='{{section.id}}' [filters]='visibleFilters' [ngClass]='{transparent: hotspotModal}'></app-section>
    <!-- <app-footer></app-footer> -->
</div>

<!-- Portrait -->
<div *ngIf='deviceService.isPortrait()' id='scroll-container' #section [ngClass]='{transparent: hotspotModal, footer: !footerIsHidden}' class='app__sections'>
    <app-section class='app__section' [content]='sectionContent'   [filters]='visibleFilters' [ngClass]='transition' [ngClass]='{footer: !footerIsHidden}' ></app-section>
    <!-- <app-footer [ngClass]='{show: !footerIsHidden}'></app-footer> -->
</div>

<!-- Skateboard -->
<app-skateboard [filePath]='"./assets/models/glb/full_car_model_hotspots.glb"' (loadComplete)='loading = false'  [ngClass]='{footer: !footerIsHidden}' #skateboard></app-skateboard>

<!-- Filters -->
<app-filters class='app__filters' [filters]='visibleFilters' [section]='sectionContent.id' (selectEmitter)='updateFilters()' #filters [ngClass]='{transparent: hotspotModal || !footerIsHidden}'></app-filters>

<!-- Scroll CTA-->
<div class='section__scroll' [ngClass]='{hidden: sectionId != "landing"}' #scroll>
    <div class='section__scroll-container'>
        <div class='section__scroll-icon'>
            <div class='section__scroll-circle'></div>
            <img class='section__scroll-arrow' src='./assets/images/arrow-down.png' />
        </div>
        <div class='section__scroll-text'>scroll</div>
    </div>
    
</div>

<!-- Modals -->
<app-learn-modal *ngIf='learnModal' [id]='learnModal'></app-learn-modal>
<app-hotspot-modal *ngIf='hotspotModal' [id]='hotspotModal' [section]='sectionId' ></app-hotspot-modal>

<app-loading *ngIf='loading'></app-loading>