import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injectable } from '@angular/core';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SectionComponent } from './components/section/section.component';
import { SkateboardComponent } from './components/skateboard/skateboard.component';
import { PromoComponent } from './components/promo/promo.component';
import { FeatureBoxComponent } from './components/feature-box/feature-box.component';
import { FiltersComponent } from './components/filters/filters.component';
import { GltfObjectComponent } from './components/gltf-object/gltf-object.component';
import { PartModelComponent } from './components/part-model/part-model.component';
import { HotspotModalComponent } from './components/hotspot-modal/hotspot-modal.component';
import { LearnModalComponent } from './components/learn-modal/learn-modal.component';
import { LoadingComponent } from './components/loading/loading.component';
import { FooterComponent } from './components/footer/footer.component';
import { ArCtaComponent } from './components/ar-cta/ar-cta.component';
import { MainComponent } from './pages/main/main.component';
import { ErrorComponent } from './pages/error/error.component';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
    options = {
        domEvents: true
    } as any;
}


@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        SectionComponent,
        SkateboardComponent,
        PromoComponent,
        FeatureBoxComponent,
        FiltersComponent,
        GltfObjectComponent,
        PartModelComponent,
        HotspotModalComponent,
        LearnModalComponent,
        LoadingComponent,
        FooterComponent,
        ArCtaComponent, 
        MainComponent, 
        ErrorComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule
    ],
    providers: [
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }
