import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
declare let gtag: Function;

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    currentSection;

    constructor() { }

    config() {
    }

    sendPageView() {
    }

    sendSectionView(params) {
    }

    sendClickEvent(label, value) {
    }

    sendEvent(action, category, label) {
    }
}
