import { Component, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { DeviceService } from 'src/app/services/device.service';
import { environment } from '../../../environments/environment';
import { openIOSARQuickLook, openSceneViewer } from './ar-launcher';
import hotspots from '../../../assets/json/hotspots.json';
console.log(environment);
@Component({
    selector: 'app-ar-cta',
    templateUrl: './ar-cta.component.html',
    styleUrls: ['./ar-cta.component.scss']
})
export class ArCtaComponent implements OnChanges {

    @Input() qrFile;
    @Input() arBaseFilename;
    @Input() section;

    environment = environment;
    constructor(private router: Router, public deviceService: DeviceService, private analyticsService: AnalyticsService) { 
    }
    
    debugMessage;
    loading;
    // ios chrome is borked and can't listen to onFocus or check visibilityState
    // so set a timer to reset the loader state.... 
    readonly IOS_CHROME_HACK_TIMEOUT = 7000;
    iOSChromeTimerHack;
    
    ngOnChanges(changes) {
        this.resetLoading();
    }

    @HostListener('window:focus', ['$event'])
    onFocus(event: any): void {
        this.resetLoading();
    }

    debug(message){
        this.debugMessage += ' ' + message + ' ';
    }

    resetLoading(){
        this.loading = false;
    }

    iOSChromeResetLoaderHack(){
        // reset load state after a time out as we have no way to know if the user has gone and
        // come back, and no way to monitor AR viewer load progress or state
        clearTimeout(this.iOSChromeTimerHack);
        this.iOSChromeTimerHack = setTimeout(() => this.resetLoading(), this.IOS_CHROME_HACK_TIMEOUT);
    }
    
    launchAR(){
        // const rootPath = window.location.href.replace(window.location.hash, '');
        // const rootPath = window.location.href.substring(0, window.location.href.lastIndexOf("/") + 1);
        // https://www.aptiv.com/virtual-event/public/assets/models/usdz/vsg.usdz
        // const rootPath = 'https://www.aptiv.com/virtual-event/public/'
        const rootPath = environment.rootAssetPath;


        if(this.loading) return;
        this.loading = true;
        
        if(this.deviceService.isiOSChrome()){
            this.iOSChromeResetLoaderHack();
        }
        if(this.deviceService.isIOS()){
            this.analyticsService.sendEvent('AR - iOS', this.section, this.arBaseFilename);
            let filePath =  `${rootPath}assets/models/usdz/${this.arBaseFilename}.usdz`;
            openIOSARQuickLook(filePath);
        }
        else if(this.deviceService.isAndroid()){
            this.analyticsService.sendEvent('AR - Android', this.section, this.arBaseFilename);
            let filePath =  `${rootPath}assets/models/glb/${this.arBaseFilename}.glb`;
            openSceneViewer(filePath);
        }
        else {
            this.analyticsService.sendEvent('AR - Failed', this.section, null);
            console.log('ArCtaComponent -> launchAR failed - not a AR capable device');
        }
    }

    launchStandalone3dViewer(){
        const model = this.arBaseFilename;
        let params = `id=${model}`
        window.open(`./viewer.html?${params}`, '_blank');
    }

}
