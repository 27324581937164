/**
 * @author mrdoob / http://mrdoob.com/
 * @author jetienne / http://jetienne.com/
 */
/** @namespace */

/**
 * provide info on THREE.WebGLRenderer
 * 
 * @param {Object} renderer the renderer to update
 * @param {Object} Camera the camera to update
*/
// THREEx.RendererStats	= function (){
export default class RendererStats {

	msMin	= 100;
	msMax	= 0;
	container;
	msDiv;
	msText;
	msTexts	= [];
	lastTime;
	domElement;

	constructor(){
		this.container = this.domElement = document.createElement( 'div' );
		this.container.style.cssText = 'width:160px;opacity:0.9;cursor:pointer';

		this.msDiv	= document.createElement( 'div' );
		this.msDiv.style.cssText = 'padding:0 0 3px 3px;text-align:left;background-color:#200;';
		this.container.appendChild( this.msDiv );

		this.msText = document.createElement( 'div' );
		this.msText.style.cssText = 'color:#f00;font-family:Helvetica,Arial,sans-serif;font-size:9px;font-weight:bold;line-height:15px';
		this.msText.innerHTML= 'WebGLRenderer';
		this.msDiv.appendChild( this.msText );

		this.msTexts	= [];
		let nLines		= 12;
		const fontSize 	= 10;
		for(let i = 0; i < nLines; i++){
			this.msTexts[i]	= document.createElement( 'div' );
			this.msTexts[i].style.cssText = `color:#f00;background-color:#311;font-family:Helvetica,Arial,sans-serif;font-size:${fontSize}px;font-weight:bold;line-height:15px`;
			this.msDiv.appendChild( this.msTexts[i] );		
			this.msTexts[i].innerHTML= '-';
		}

		this.lastTime	= Date.now();
	}

	update(webGLRenderer){
        // console.log("RendererStats -> update -> webGLRenderer", webGLRenderer);
		if(webGLRenderer.renderer) webGLRenderer = webGLRenderer.renderer;

		if( Date.now() - this.lastTime < 1000/30 )	return;
		this.lastTime	= Date.now()

		let i	= 0;
		this.msTexts[i++].textContent = "== Memory =====";
		this.msTexts[i++].textContent = "Programs: "	+ webGLRenderer.info.programs.length;
		this.msTexts[i++].textContent = "Geometries: "+webGLRenderer.info.memory.geometries;
		this.msTexts[i++].textContent = "Textures: "	+ webGLRenderer.info.memory.textures;

		this.msTexts[i++].textContent = "== Render =====";
		this.msTexts[i++].textContent = "Calls: "	+ webGLRenderer.info.render.calls;
		this.msTexts[i++].textContent = "- Frame: "	+ webGLRenderer.info.render.frame;
		// this.msTexts[i++].textContent = "- Lines: "	+ webGLRenderer.info.render.lines;
		// this.msTexts[i++].textContent = "- Points: "	+ webGLRenderer.info.render.points;
		this.msTexts[i++].textContent = "- Triangles: "	+ webGLRenderer.info.render.triangles;
		if((window.performance as any).memory){
			this.msTexts[i++].textContent = "== Chrome Memory =====";
			this.msTexts[i++].textContent = "- jsHeapSizeLimit: "	+ this.bytesToMB((window.performance as any).memory.jsHeapSizeLimit);
			this.msTexts[i++].textContent = "- totalJSHeapSize: "	+ this.bytesToMB((window.performance as any).memory.totalJSHeapSize);
			this.msTexts[i++].textContent = "- jsHeapSizeLimit: "	+ this.bytesToMB((window.performance as any).memory.usedJSHeapSize);
		}
	}

	bytesToMB(bytes){
		const sizeInMB = (bytes / (1024*1024)).toFixed(2);
		return sizeInMB;
	}

}