<div class='navbar__container'>
    <img class='navbar__brand' src='./assets/images/aptiv-logo.svg' (click)='goToSection("landing")' />
    <div class='navbar__fade' [ngClass]='setNavFade()'></div>
    <div class='navbar__links' #navbarLinks>
        
        <div class='navbar__link' *ngFor='let link of links' (click)='goToSection(link.id)' [ngClass]='{active: link.active}' id='link-{{link.id}}'>
            <div class='navbar__text' [innerHTML]='link.navigation'></div>
            <div class='navbar__underline' *ngIf='link.active'></div>
        </div>

    </div>
</div>