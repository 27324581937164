/**
 * Opens Android Scene Viewer
 */
export function openSceneViewer(glbSrc) {

    // This is necessary because the original URL might have query
    // parameters. Since we're appending the whole URL as query parameter,
    // ? needs to be turned into & to not lose any of them.
    const anchor = document.createElement('a');
    const gltfSrc = glbSrc!.replace('?', '&');
    const location = self.location.toString();
    const locationUrl = new URL(location);
    const modelUrl = new URL(gltfSrc);

    const noArViewerSigil = '#model-viewer-no-ar-fallback';
    locationUrl.hash = noArViewerSigil;

    // modelUrl can contain title/link/sound etc.
    // These are already URL-encoded, so we shouldn't do that again here.
    let intentParams = `?file=${modelUrl.toString()}&mode=ar_only`;
    const intent = `intent://arvr.google.com/scene-viewer/1.0${
        intentParams}#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${
        encodeURIComponent(locationUrl.toString())};end;`;
    anchor.setAttribute('href', intent);
    anchor.click();
}

/**
 * Opens iOS Quick Look
 */
export function openIOSARQuickLook(iosSrc) {
    const modelUrl = new URL(iosSrc);
    const anchor = document.createElement('a');
    anchor.setAttribute('rel', 'ar');
    const img = document.createElement('img');
    anchor.appendChild(img);
    anchor.setAttribute('href', modelUrl.toString());
    // anchor.setAttribute('target', '_blank');
    anchor.click();
    anchor.removeChild(img);
    document.removeChild(anchor);
}