import { Component, EventEmitter, Input, OnInit, HostBinding, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

import data from '../../../assets/json/content.json';

@Component({
    selector: 'app-learn-modal',
    templateUrl: './learn-modal.component.html',
    styleUrls: ['./learn-modal.component.scss']
})
export class LearnModalComponent implements OnInit, OnChanges {

    @Input() id;
    data = data;
    content;

    constructor(private router: Router) { }

    ngOnInit(): void {
    }

    ngOnChanges() {
        this.content = this.data.find((c) => c.id == this.id);
    }

    close() {
        this.router.navigate([], {queryParams: {learn: null}, queryParamsHandling: 'merge'})
    }    

}
