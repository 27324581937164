import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    @HostBinding('style.height.px')
    get height() {
        return window.innerHeight;
    }

    // Firefox Bugfix
    @HostBinding('style.width.px') 
    get width() {
        return window.innerWidth;
    }

}
