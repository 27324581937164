import { Component } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AnalyticsService } from './services/analytics.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {

    title = 'aptiv-sva-virtual-tool-webapp';

    constructor(private router: Router, private analyticsService: AnalyticsService) {
        
        this.analyticsService.config();
        router.events
            .pipe(filter((event) => event instanceof ActivationEnd))
            .subscribe((e: ActivationEnd) => {
                if (environment['trackingId']) {
                    this.analyticsService.sendSectionView(e.snapshot.queryParams);
                }

            });
    }

}
