
<div class='promo__media'(click)='openLink()'>
    <img class='promo__image' src='{{"./assets/images/" + content.media}}' />
    <img class='promo__play' src='./assets/images/video-play-icon.svg' *ngIf='content.isVideo'/>
    
</div>

<div class='promo__content '>
    <div class='promo__title' (click)='openLink()'>{{content.title}}</div>
    <div class='promo__description'>{{content.description}}</div>
</div>

