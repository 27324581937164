<div class='modal__close' (click)='close()'><img src='./assets/images/close-icon.svg' /></div>
<div class='hotspot__container' #container *ngIf='content'>

    <div class='hotspot__content'>
        <div class='hotspot__text'>
            <div class='hotspot__subheader'>{{content.subheader}}</div>
            <div class='hotspot__header'>{{content.header}}</div>
            <div class='hotspot__description'>{{content.description}}</div>
        </div>

        <div *ngIf='!hidePrivateContent()'>
            <app-ar-cta arBaseFilename='{{content.model}}' [qrFile]='content.section + "-" + content.group'
                [section]='id'></app-ar-cta>
        </div>


    </div>

    <!-- Unlocked Content -->
    <div class='hotspot__model-container' *ngIf='!hidePrivateContent()'>

        <video controls autoplay loop *ngIf='content.mediaPreview' class='hotspot__video' id='video'>
            <source src='./assets/videos/{{content.mediaPreview}}'>
        </video>

        <app-part-model *ngIf='!content.mediaPreview' class='hotspot__model'
            [filePath]='"./assets/models/glb/" + content.model + ".glb"' [content]='content' [showPoster]='content.showPoster'></app-part-model>
    </div>

    <!-- Locked Content -->
    <div class='hotspot__model-container' *ngIf='hidePrivateContent()'>
        <div class='hotspot__locked'>
            <img class='hotspot__locked-image' src='./assets/images/padlock.svg' />
            <div class='hotspot__locked-title'>THIS CONTENT IS LOCKED</div>
            <div class='hotspot__locked-description'>Talk to a specialist to view this content</div>
            <a class='hotspot__locked-contact btn__arrow orange' href='mailto:virtual-tools@aptiv.com?subject=Aptiv%20Virtual%20Tool%20Information%20Request&body=Please%20have%20an%20Aptiv%20Specialist%20contact%20me%20to%20explore%20additional%20information%20within%20the%20Aptiv%20Virtual%20Tool.'
                target='_blank'>Contact Us</a>
        </div>
    </div>

    <div class='hotspot__pages'>
        <div *ngFor='let hotspot of hotspotArr;' class='hotspot__dot'
            [ngClass]='{active: hotspot.group == content.group}' (click)='goToHotspot(hotspot.group)'></div>
    </div>


    <div class='hotspot__previous' (click)='previousHotspot()'>
        <img src='./assets/images/chevron-left.svg' />
    </div>
    <div class='hotspot__next' (click)='nextHotspot()'>
        <img src='./assets/images/chevron-right.svg' />
    </div>
</div>