import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {

    // Touch and size detection in favor of user agent
    //https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent

    constructor() { }

    isIOS() {
        return (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    isAndroid() {
        return navigator.userAgent.toLowerCase().indexOf("android") > -1;
    }

    isMobileOS() {
        return this.isIOS() || this.isAndroid();
    }

    isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    isiOSChrome() {
        return navigator.userAgent.indexOf('CriOS') !== -1;
    }

    isiOSSafari() {
        var isFirefox = "InstallTrigger" in window;

        var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        let isiOSSafari = isSafari && iOS && !isFirefox;
        return isiOSSafari;
    }

    isWindowsChrome() {
        return (navigator.userAgent.toLowerCase().indexOf("chrome") !== -1 &&
            navigator.userAgent.toLowerCase().indexOf("windows") !== -1)
    }


    isMobile() {
        return this.touchIsEnabled() && this.isMobileSize();
    }

    touchIsEnabled() {
        return ('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0);
    }

    isMobileTablet() {
        return this.isMobileSize() || this.isTabletSize();
    }

    isMobileSize() {
        return window.innerWidth < 710;
    }

    isTabletSize() {
        return window.innerWidth >= 710 && window.innerWidth < 992;
    }

    isDesktopSize() {
        return window.innerWidth >= 992;
    }

    isLandscape() {
        return window.innerWidth > window.innerHeight;
    }

    isPortrait() {
        return window.innerWidth < window.innerHeight;
    }

    isLandscapeMobile() {
        return (this.isMobileSize() || this.isTabletSize()) && this.isLandscape();
    }
}
