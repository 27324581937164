<div class='modal__close' (click)='close()'><img src='./assets/images/close-icon.svg' /></div>
<div class='learn__container'>
    <div class='learn__intro'>
        <div class='learn__header'>{{content.learnMoreHeader}}</div>
        <div class='learn__description'>{{content.learnMoreDescription}}</div>
    </div>
    <div class='learn__promos'>
        <app-promo class='learn__promo' *ngFor='let promo of content.promos' [content]='promo'></app-promo>
    </div>
    <app-feature-box [content]='content' class='learn__feature'></app-feature-box>
</div>