import { Injectable } from '@angular/core';
import * as Hammer from 'hammerjs';

@Injectable({
    providedIn: 'root'
})
export class ListenerService {

    throttleTime;
    wheelListenerFn;
    wheelOpt;
    wheelEvent;

    constructor() { }

    initResizeListeners(fn) {
        window.addEventListener('resize', fn);
        window.addEventListener('orientationchange', fn);
    }

    initHammer(refs, fn) {
        for (let ref of refs) {
            let hammertime = new Hammer(ref, { threshold: 15 });
            hammertime.domEvents = true;
            hammertime.threshold = 10;
            hammertime.on('pan', (e) => {
                if (e.distance < 20) return;
                this.throttle(fn, 800, e)
            });
        }
    }

    initWheel(fn) {
        if (this.wheelListenerFn) return;
        this.wheelOpt = this.supportsPassive() ? { passive: false } : false;
        this.wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
        this.wheelListenerFn = ((e) => {
            e.preventDefault();
            this.throttle(fn, 1000, e)
         }).bind(this)
        window.addEventListener(this.wheelEvent, this.wheelListenerFn, this.wheelOpt);
    }

    removeWheel() {
        if (!this.wheelListenerFn) return;
        window.removeEventListener(this.wheelEvent, this.wheelListenerFn, this.wheelOpt);

        this.wheelListenerFn = null;
        this.wheelEvent = null;
        this.wheelOpt = null;
    }

    // ============= Helpers ============= //

    throttle(fn, wait, event) {
        if (this.throttleTime + wait - Date.now() < 0 || !this.throttleTime) {
            fn(event);
            this.throttleTime = Date.now();
        }
    }

    supportsPassive() {
        var supportsPassive = false;
        try {
          window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
            get: function () { supportsPassive = true; } 
          }));
        } catch(e) {}
        return supportsPassive;
    }

    detectTrackPad(e) {
        var isTrackpad = false;
        if (e.wheelDeltaY) {
            if (e.wheelDeltaY === (e.deltaY * -3)) {
                isTrackpad = true;
            }
        }
        else if (e.deltaMode === 0) {
            isTrackpad = true;
        }
        return isTrackpad;
    }

}
