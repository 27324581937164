import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js/dist/tween.esm.js';

import { Mesh, MeshBasicMaterial, VideoTexture } from 'three';

export default class BGVideo {

    static currentVisible : BGVideo;
    sectionId   : string;
    video       : HTMLVideoElement;
    texture     : VideoTexture;
    mesh        : Mesh;
    fadeTween   : TWEEN.Tween;

    constructor(private scene, private camera, private sectionData){
        this.sectionId = this.sectionData.id;
    }

    async init(){
        this.video      = await this.createVideoElem(this.sectionData.video);
        this.texture    = this.createVideoTexture(this.video);
        this.mesh       = this.createVideoMesh(this.sectionData, this.texture);
    }

    async createVideoElem(videoName) {
        let video = document.createElement('video');
        video.crossOrigin = 'anonymous';
        video.preload = '';
        video.autoplay = true;
        video.muted = true;
        video.src = `./assets/videos/${videoName}`;
        video.loop = true;
        
        await video.play();
        return video;
    }

    createVideoTexture(video) {
        let texture = new THREE.VideoTexture(video);
        texture.name = `BGVideo::${this.sectionData.video}`;
        // hides wierd green edge of video texture - ASVT-228
        texture.offset = new THREE.Vector2(.0, .001);
    
        return texture;
    }

    createVideoMesh(section, texture) {
        let width = parseFloat(section.videoWidth);
        let height = width / 1.7;
        let geometry = new THREE.PlaneBufferGeometry(width, height);
        let material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.FrontSide, transparent: true, opacity: 0 });

        // makes the background darker
        material.color = new THREE.Color( 0x999999 );
        material.color.convertSRGBToLinear();

        var imageObject = new THREE.Mesh(geometry, material);
        imageObject.rotation.set(
            parseFloat(section.videoRotX),
            parseFloat(section.videoRotY),
            parseFloat(section.videoRotZ)
        );
        imageObject.position.set(
            parseFloat(section.videoPosX),
            parseFloat(section.videoPosY),
            parseFloat(section.videoPosZ)
        );
        imageObject.renderOrder = -100;
        imageObject.name = `background-${section.id}`;
        imageObject.visible = false;

        if(this.sectionData.flatBackground){
            this.camera.add(imageObject);
        }else{
            this.scene.add(imageObject);
        }
        return imageObject;
    }
    
    fadeIn(){
        if(BGVideo.currentVisible) 
            BGVideo.currentVisible.fadeOut();
        
        BGVideo.currentVisible = this;
        this.mesh.visible = true;

        let onComplete = () => {
            (this.mesh.material as MeshBasicMaterial).transparent = false;
        }
        this.doFadeTween(1, onComplete);
    }

    fadeOut(){
        (this.mesh.material as MeshBasicMaterial).transparent = true;

        let onComplete = () => {
            this.mesh.visible = false;
        }
        this.doFadeTween(0, onComplete);
    }

    doFadeTween(opacity, onComplete){
        if(this.fadeTween)
            this.fadeTween.stop();
    
        const fadeTime = 1000;
        this.fadeTween = new TWEEN.Tween(this.mesh.material)
            .to({opacity:opacity}, fadeTime)
            .onComplete(onComplete)
            .start();
    }

}
