import { Component, Input, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { DeviceService } from 'src/app/services/device.service';

import filterData from '../../../assets/json/filters.json';

@Component({
    selector: 'app-section',
    templateUrl: './section.component.html',
    styleUrls: ['./section.component.scss'],
})
export class SectionComponent {
    
    @Input() content;
    @Input() filters;

    constructor(private router: Router, private deviceService: DeviceService, private analyticsService: AnalyticsService) {}

    get activeFilter() {
        return filterData.find((filter) => filter['active'] && filter.section == this.content.id);
    }

    @HostBinding('style.height')
    get height() {
        let height = this.deviceService.isLandscape() ? `${window.innerHeight}px` : 'initial';
        return height;   
    }

    openModal(id) {
        this.analyticsService.sendEvent('learn', this.content.id, id);
        this.router.navigate([], {queryParams: {learn: id}, queryParamsHandling: 'merge'});
    }

}
