<div class='section__container'>
    <div class='section__content' *ngIf='content'>

        <div class='section__subheader' *ngIf='content?.subheader' [innerHTML]='content.subheader'></div>
        <div class='section__header' [innerHTML]='activeFilter?.header || content?.header'></div>
        <div class='section__description' [innerHTML]='activeFilter?.description || content?.description'></div>


        <app-ar-cta *ngIf='content.id == "landing"' [qrFile]='"skateboard"' [section]='content?.id' arBaseFilename='full_car'></app-ar-cta>


        <!-- Section CTA -->
        <!-- <div class='section__cta btn__arrow orange' *ngIf='content.id != "landing" && content.id != "evolution"'
            (click)='openModal(content.id)'>Learn More</div> -->
        
    </div>

</div>