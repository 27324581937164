
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import * as THREE from 'three';
export {EffectComposer};

export default class SceneComposer {

    static ORANGE         : number = 0xF84018;
    static TEAL           : number = 0x00ac9e;
    static OUTLINE_COLOR  : number = SceneComposer.ORANGE;

    composer        : EffectComposer;
    effectFXAA      : ShaderPass;
    outlinePass     : OutlinePass;

    constructor(private renderer: THREE.WebGLRenderer, private scene: THREE.Scene, private camera: THREE.Camera) {

        let composer, effectFXAA, outlinePass;
        // effectFXAA = new ShaderPass( FXAAShader );
        // effectFXAA.uniforms[ 'resolution' ].value.x = 1 / ( (window.innerWidth/2) * this.renderer.getPixelRatio() );
        // effectFXAA.uniforms[ 'resolution' ].value.y = 1 / ( (window.innerHeight/2) * this.renderer.getPixelRatio() );

        outlinePass = new OutlinePass( new THREE.Vector2( 512, 512 ), this.scene, this.camera );
        outlinePass.edgeStrength = 10;
        outlinePass.edgeGlow = 1;
        outlinePass.edgeThickness = 1;
        outlinePass.pulsePeriod = 5;

        outlinePass.visibleEdgeColor.set( SceneComposer.OUTLINE_COLOR );
        outlinePass.hiddenEdgeColor.set( SceneComposer.OUTLINE_COLOR );

        composer = new EffectComposer( this.renderer );
        var renderPass = new RenderPass( this.scene, this.camera );
        composer.addPass( renderPass );
        composer.addPass( outlinePass );
        // composer.addPass( effectFXAA );

        composer.renderTarget1.texture.encoding = THREE.sRGBEncoding;
        composer.renderTarget2.texture.encoding = THREE.sRGBEncoding;
        this.outlinePass = outlinePass;
        this.composer = composer;
    }

    render(){
        if(this.outlinePass.selectedObjects.length === 0)
            this.renderer.render(this.scene, this.camera);
        else 
            this.composer.render();
    }
}