<div class='cta' *ngIf='!deviceService.isMobileOS()'>
    <img src='./assets/qrs/{{environment.qrs}}/{{qrFile}}.png' class='cta__qr' />
    <div class='cta__instructions'>
        Scan this code with your device to view in your space
    </div>
</div>

<div *ngIf='deviceService.isMobileOS() && !loading' class='cta__mobile' (click)='launchAR()'>
    View model in your space
    <img src='./assets/images/ar-icon.svg' />
</div>
<div *ngIf='loading' class='cta__loading'>Loading Augmented Reality</div>


