import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class GuardService implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route, state) {
        console.log(route);
        console.log(state);

        if (route.url.length == 0 && !route.queryParams.section) {
            return this.router.parseUrl('/?section=landing');
        }
        return true;
    }
}
