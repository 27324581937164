import Stats from 'three/examples/jsm/libs/stats.module';
import RendererStats from './threex.rendererstats';

export default class ThreeStats {

    stats:          Stats;
    rendererStats:  RendererStats;
    showFPS:        boolean = false;

    constructor(){
        window.addEventListener('keydown', this.onKeydownHandler.bind(this));
    }
    
    createStats() {
        this.createThreeJSStats();
        this.createCustomStats();
    }

    createThreeJSStats(){
        // standard threejs stats
        this.stats = Stats();
        if(this.showFPS) document.body.appendChild( this.stats.dom );  
    }

    createCustomStats(){
        // custom stats counter with draw call metrics
        this.rendererStats = new RendererStats();
        this.rendererStats.domElement.style.position = 'fixed';
        this.rendererStats.domElement.style.zIndex	= '20000'
        this.rendererStats.domElement.style.left	= '0px'
        this.rendererStats.domElement.style.top	    = '45px'
        if(this.showFPS) document.body.appendChild( this.rendererStats.domElement );
    }

    onKeydownHandler(event: KeyboardEvent) {
        if(event.code == 'Minus'){
            this.toggleStats();
        }
    }

    toggleStats(){
        this.showFPS = !this.showFPS;
        this.setStatsState();
    }

    setStatsState(){
        if(this.showFPS){
            if(!this.stats) this.createStats();
            document.body.appendChild( this.stats.dom );
            document.body.appendChild( this.rendererStats.domElement );
        }
        else{
            document.body.removeChild( this.stats.dom );
            document.body.removeChild( this.rendererStats.domElement );
        }
    }

    update(renderer){
        if(!this.showFPS) return;
        if(!this.stats) this.createStats();
        this.stats.update();
        this.rendererStats.update(renderer);
    }
}
