import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    animations: [trigger('fadeTrigger', [
        transition(':enter', [
          style({ opacity: 0 }),
          animate('300ms', style({ opacity: 1 })),
        ]),
        transition(':leave', [
          animate('300ms', style({ opacity: 0 }))
        ])
    ])],
})
export class FiltersComponent {

   @Input() section;
    @Input() set filters(value) {
        this._filters = null;
        setTimeout(() => this._filters = value);
    };
    get filters() {
        return this._filters;
    }
    _filters;
    

    @Output() selectEmitter = new EventEmitter();


    constructor(private analyticsService: AnalyticsService) { }


    selectFilter(filter) {
        let val = !filter.active;
        for (let filter of this.filters) {
            filter.active = false;
        }
        filter.active = val;
        this.analyticsService.sendEvent('filter', this.section, filter.active);
        this.selectEmitter.emit();

    }

}
